import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useAuth } from '@/hooks/use-auth';

import Banner from '../banner/banner';
import SignUpModal from '../modals/sign-up-modal';

export default function SingleClickActivate() {
  const auth = useAuth();
  const { user } = auth;
  const router = useRouter();
  const { t } = useTranslation('pages/home');
  const [showModaCreatelUser, setShowModalCreateUser] = useState(false);

  const handleSignUp = () => {
    if (user) {
      router.push('/dashboard/overview');
      return;
    }
    setShowModalCreateUser(true);
  };

  const handleCloseModal = () => {
    setShowModalCreateUser(false);
  };

  return (
    <div className='relative flex w-full items-center justify-center'>
      <div className='w-full max-w-[1400px] max-[1024px]:p-5 lg:px-5 lg:pb-40'>
        <div className='relative flex w-full flex-col items-center gap-10 2xl:flex-row'>
          <Banner />

          <div className='flex flex-col gap-y-5'>
            <div className='flex flex-col gap-y-5 text-left'>
              <div className='flex flex-col gap-y-2.5 self-stretch font-[700] leading-none'>
                <img alt='Bolt' className='h-20 w-16' src='/assets/images/banner/ray.svg' />
                <h2 className='text-xl text-[#565B63]'>{t('boost')}</h2>
                <h3 className='text-4xl text-white'>{t('boost_sub_title')}</h3>
              </div>
              <p className='text-base font-medium leading-snug text-[#8A8A9A] xl:max-w-[560px]'>{t('boost_description')}</p>
            </div>

            <p className='text-left text-base font-medium leading-snug text-[#8A8A9A]'>{t('boost_servers_around')}</p>

            <div className='flex flex-col gap-4 pt-5 font-bold'>
              <div className='flex items-center justify-start gap-8'>
                <div className='flex items-center justify-start'>
                  <div className='-mr-8 min-w-[46px]'>
                    <Image className='size-[46px] rounded-full border-4 border-black' src='/assets/images/banner/avatar-arcade-fighter-man.webp' width={50} height={50} alt='avatar' />
                  </div>
                  <div className='-mr-8 min-w-[46px]'>
                    <Image className='size-[46px] rounded-full border-4 border-black' src='/assets/images/banner/avatar-comic-shooter-man.webp' width={50} height={50} alt='avatar' />
                  </div>
                  <div className='-mr-8 min-w-[46px]'>
                    <Image className='size-[46px] rounded-full border-4 border-black' src='/assets/images/banner/avatar-survival-man.webp' width={50} height={50} alt='avatar' />
                  </div>
                </div>
                <div className='flex flex-col text-left leading-tight'>
                  <div className='text-sm text-white'>
                    <span className='uppercase'>+3,000,000</span>
                  </div>
                  <div className='text-nowrap text-xs text-zinc-400'>
                    <span className='uppercase'>{t('boost_numbers_users')}</span>
                  </div>
                </div>
              </div>

              <Link href='/tutorial/windows' className='w-full rounded-lg bg-blue-300 p-2.5 text-center text-sm font-bold text-white transition-opacity duration-200 hover:opacity-80 hover:shadow-xl'>
                {t('boost_button')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {showModaCreatelUser && <SignUpModal showModalSingIn={handleSignUp} closeModalSingUp={handleCloseModal} />}
    </div>
  );
}
